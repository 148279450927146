.icon-card-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;

  &.green .icon-card svg {
    fill: var(--primary-color-hover-inverse);
  }

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
  }

  .icon-card {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 2rem;
    border: 1px solid #ddd;
    padding: 2rem;
    border-radius: 10px;

    @media (max-width: 800px) {
      grid-template-columns: 1fr;
      grid-gap: 1rem;
      text-align: center;
      justify-items: center;
    }

    & h2 {
      font-size: 1.2rem;
      line-height: 1.4;
    }
    & p.desc {
      font-size: 1rem;
    }
    & p.footer {
      font-size: 1rem;
      font-weight: bold;
      color: #666;
      margin-top: 0.5rem;
    }
    & svg {
      width: 50px;
      height: 50px;
      fill: var(--secondary-color);
    }
  }
}
