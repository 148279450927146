@import 'computing-den-lib/src/client/base.css';
@import url('./base.css');
@import url('./Intro.css');
@import url('./ImageCarousel.css');
@import url('./GalleryDialog.css');
@import url('./IconCardGrid.css');

/* @media (max-height: 700px) { */
/*   :root { */
/*     --intro-height: 90vh; */
/*   } */
/* } */

section:nth-child(odd) {
  background: var(--section-bg-1);
}
section:nth-child(even) {
  background: var(--section-bg-2);
}

#services {
  margin-top: var(--intro-height);
  position: relative;

  > .content {
    margin: 0 auto;
    padding: var(--section-vertical-padding) var(--section-horizontal-padding);
    max-width: var(--section-max-width-narrow);

    /* .button-container { */
    /*   display: flex; */
    /*   margin-top: var(--section-heading-and-body-margin); */

    /*   @media (max-width: 700px) { */
    /*     justify-content: center; */
    /*     font-size: 0.7rem; */
    /*   } */
    /* } */
  }
}

#tech {
  position: relative;

  > .content {
    margin: 0 auto;
    padding: var(--section-vertical-padding) var(--section-horizontal-padding);
    max-width: var(--section-max-width-narrow);

    .grid {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      justify-content: space-between;
      row-gap: 1rem;
      /* grid-gap: 3rem; */

      .tech-stack-item {
        display: grid;
        grid-gap: 0.5rem;
        justify-items: center;

        & svg {
          width: 96px;
          height: 96px;
        }
      }
      @media (max-width: 1024px) {
        /* grid-gap: 1rem; */
        .tech-stack-item svg {
          width: 48px;
          height: 48px;
        }
      }
      @media (max-width: 800px) {
        grid-template-columns: repeat(3, 1fr);
        .tech-stack-item svg {
          width: 48px;
          height: 48px;
        }
      }
    }

    /* .button-container { */
    /*   display: flex; */
    /*   margin-top: var(--section-heading-and-body-margin); */

    /*   @media (max-width: 700px) { */
    /*     justify-content: center; */
    /*     font-size: 0.7rem; */
    /*   } */
    /* } */
  }
}

#portfolio {
  position: relative;

  &:hover {
    cursor: grab;
  }

  > .heading-content {
    margin: 0 auto;
    padding: var(--section-vertical-padding) var(--section-horizontal-padding) 0 var(--section-horizontal-padding);
    max-width: var(--section-max-width-narrow);

    .section-heading-and-buttons {
      display: flex;
      margin-bottom: var(--section-heading-and-body-margin);
      align-items: center;
    }

    & h1 {
      text-align: left;
      margin-bottom: 0;
      @media (max-width: 600px) {
        font-size: 1.5rem;
      }
    }

    & h3 {
      text-align: left;
    }
  }
  .image-carousel {
    /* Make the ios scrollbar appear at the very bottom of section */
    padding-bottom: var(--section-vertical-padding);
  }
}

#about {
  position: relative;

  > .content {
    margin: 0 auto;
    padding: var(--section-vertical-padding) var(--section-horizontal-padding);
    max-width: var(--section-max-width-narrow);
    /* display: grid; */
    /* grid-template-columns: 300px auto; */
    /* gap: 1rem; */
    /* display: flex; */
    /* align-items: center; */

    /* .media { */
    /*   img { */
    /*     width: 100%; */
    /*   } */
    /* } */

    & figure.sean {
      float: right;
      margin: 0 0 1rem 2rem;
      display: flex;
      flex-direction: column;
      width: min-content; /* When there are few images, the caption expands the width. Setting width: min-content avoids that.  */

      & img {
        box-shadow: 4px 5px 15px #0003;
        background-color: var(--sean-bg-color);
        border-radius: 5px;
        /* max-width: 100%; */
        height: auto;
        width: 180px;

        @media (max-width: 800px) {
          width: 150px;
        }

        @media (max-width: 500px) {
          width: 100px;
        }
      }

      & figcaption {
        text-align: center;
        font-size: 0.7rem;

        /* @media (max-width: 800px) { */
        /*   font-size: 0.7rem; */
        /* } */
        @media (max-width: 500px) {
          font-size: 0.6rem;
        }
      }
    }

    & p {
      /* text-align: justify; */
    }

    .clear {
      clear: both;
    }
  }
}

#pricing {
  position: relative;

  > .intro-content {
    margin: 0 auto;
    padding: var(--section-vertical-padding) var(--section-horizontal-padding) 0;
    max-width: var(--section-max-width-narrow);
  }

  .case-study {
    &:hover {
      cursor: grab;
    }

    &:last-child {
      .image-carousel {
        padding-bottom: var(--section-vertical-padding);
      }
    }

    .heading-and-buttons {
      margin: 0 auto;
      padding: var(--section-heading-and-body-margin) var(--section-horizontal-padding) 1rem
        var(--section-horizontal-padding);
      /* padding: var(--section-heading-and-body-margin) var(--section-horizontal-padding) 2rem */
      /*   var(--section-horizontal-padding); */
      max-width: var(--section-max-width-narrow);
      display: flex;
      align-items: center;

      & h2 {
        font-size: 1.5rem;
        text-align: left;
        margin-bottom: 0;
        @media (max-width: 600px) {
          font-size: 1.2rem;
        }
      }
    }

    .image-carousel {
      /* Leave a little space for ios scrollbars at the bottom */
      padding-bottom: 1rem;
    }
  }
}

#testimonials {
  position: relative;

  > .content {
    margin: 0 auto;
    padding: var(--section-vertical-padding) var(--section-horizontal-padding);
    max-width: var(--section-max-width-narrow);

    .testimonial {
      box-shadow: 4px 5px 20px 0px rgb(0 0 0 / 14%);
      display: grid;
      grid-template-columns: auto 1fr;
      /* grid-gap: 1rem; */
      align-items: center;
      /* display: flex; */
      /* flex-direction: column; */

      border-radius: 5px;
      position: relative;
      /* overflow: hidden; */

      /* padding: 1rem; */
      /* padding-top: 3rem; */
      /* margin-top: 4rem; */
      /* padding: 1rem; */

      & svg.quote {
        position: absolute;
        width: 80px;
        height: 80px;
        top: -40px;
        right: 2rem;
        transform: scale(-1, -1);
      }

      & img {
        /* margin-right: 1rem; */
        display: block;
        /* max-height: 150px; */
        /* height: 100%; */
        /* min-height: 0; */
        /* width: 180px; */
        /* object-fit: cover; */
        border-radius: 5px 0 0 5px;
        border: 1px solid #eee;

        object-fit: cover;
        width: 150px;
        height: 100%;

        /* width: 150px; */
        /* border-radius: 50%; */
        /* border: 1px solid rgb(255 255 255); */
        /* position: absolute; */

        /* bottom: -23px; */
        /* left: 1rem; */
        /* border: 1px solid #ccc; */
      }
      .body {
        padding: 2rem;
        & p {
          /* padding: 1rem; */
        }
        .footer {
          margin-top: 1rem;

          & p.name {
            /* padding-left: 120px; */
            /* text-align: center; */
            font-size: 0.8em;
            font-weight: bold;
          }
          & p.title {
            /* padding-left: 120px; */
            /* text-align: center; */
            font-size: 0.8em;
            margin-top: 0rem;
          }
        }
      }

      @media (max-width: 700px) {
        overflow: unset;
        margin-bottom: 3rem;

        & img {
          position: absolute;
          width: 80px;
          height: 80px;
          border-radius: 50%;
          box-shadow: 4px 5px 15px 0px rgba(0, 0, 0, 20%);
          border: 0;
          /* border: 1px solid #eee; */
          bottom: -40px;
          left: calc(50% - 40px);
          margin: 0;
        }

        .body {
          padding-bottom: 3rem;
          .footer {
            & p {
              text-align: center;
            }
          }
        }
      }
    }
  }
}
