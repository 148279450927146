#gallery-dialog {
  width: 100%;
  height: 100%;
  margin: auto;
  border: 0;
  outline: 0;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  background: none;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);

  &::backdrop {
    background-image: linear-gradient(150deg, #3e3e3e, #000);
    opacity: 0.9;
  }

  &:focus-visible {
    border: 0;
    outline: 0;
  }

  & button,
  & button:focus,
  & button:hover {
    background: none;
    border: 0;
    outline: 0;
  }

  & button {
    background: #00000040;
    border-radius: 50%;
    z-index: 2;
    padding: 0.5rem;
    width: 60px;
    height: 60px;
    transition: all 0.2s ease-in-out;
    user-select: none;
    /* backdrop-filter: blur(5px); */
    /* -webkit-backdrop-filter: blur(5px); */

    &:hover,
    &:focus,
    &:focus-visible {
      cursor: pointer;
      background: #00000070;
    }

    &.close {
      position: fixed;
      top: 1rem;
      right: 1rem;
    }

    &.next {
      position: fixed;
      top: 50%;
      right: 1rem;
    }

    &.prev {
      position: fixed;
      top: 50%;
      left: 1rem;
    }

    & svg {
      /* filter: drop-shadow(4px 4px 3px rgba(0, 0, 0, 0.7)); */
      width: 100%;
      height: 100%;
      color: white;
    }
  }

  /* .content { */
  /*   flex: 1; */
  /*   display: flex; */
  /*   justify-content: center; */
  /*   /\* flex-direction: column; *\/ */
  /*   border-radius: 5px; */
  /*   overflow: hidden; */
  /*   position: relative; */

  /* } */
  & img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    z-index: 1;
    align-self: center;
    width: auto;
    height: auto;
    min-width: 0;
    min-height: 0;
    border-radius: 5px;
  }

  .caption {
    align-self: center;
    margin-left: 2rem;
    padding: 2rem;
    /* text-align: center; */
    background: #505050;
    color: white;
    width: 380px;
    border-radius: 5px;
    border: 1px solid #686868;
    min-width: 300px;
    /* font-size: 1.2rem; */

    & h2 {
      margin: 0;
      font-size: inherit;
      font-weight: bold;

      & + * {
        margin-top: 0.5rem;
      }
    }

    & p + p {
      margin-top: 0rem;
    }

    @media (max-width: 1024px) {
      display: none;
    }
    @media (max-width: 1200px) {
      width: 300px;
      font-size: 0.9rem;
      & h2 {
        font-size: 0.9rem;
      }
    }
  }
}
