.image-carousel {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 3rem;
  overflow-x: scroll;
  scrollbar-width: none;
  padding: 1rem 3rem 0 3rem;
  justify-content: start;

  @media (max-width: 800px) {
    padding-left: 0;
    padding-right: 0;
  }

  .image {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 0;
    background: white;
    border-radius: 10px;
    box-shadow: 4px 5px 15px 0px rgba(0, 0, 0, 20%);
    width: min-content; /* When there are few images, the caption expands .image's width. Setting width: min-content avoids that.  */

    /* @media (max-width: 500px) { */
    /*   &:not(:last-child) { */
    /*     border-bottom: 1px solid #d9d9d9; */
    /*   } */
    /* } */

    @media (max-width: 800px) {
      background: none;
      border-radius: unset;
      box-shadow: unset;
    }

    & + .image {
    }

    & img {
      width: 100vw;
      max-width: 450px;
      border-radius: 10px 10px 0 0;
      transition: all 0.2s ease-out;
      /* box-shadow: 4px 5px 15px 0px rgba(0, 0, 0, 20%); */
      z-index: 1;
      border-bottom: 1px solid #ccc;

      @media (max-width: 800px) {
        max-width: unset;
      }

      &.without-shadow {
        box-shadow: none;
        &:hover {
          box-shadow: none;
        }
      }

      &:hover {
        /* border: 5px solid rgb(27, 150, 255); */
        transform: translate3d(0px, -5px, 0);
        box-shadow: 6px 7px 20px 0px rgba(0, 0, 0, 30%);
        cursor: pointer;
        /* border-radius: 10px; */
      }

      @media (max-width: 500px) {
        border-radius: 0;
      }
    }

    .caption {
      /* font-size: 1.2rem; */
      padding: 2rem;

      @media (max-width: 500px) {
        padding: var(--section-vertical-padding) var(--section-horizontal-padding);
      }

      & h2 {
        margin: 0;
        font-weight: bold;
        font-size: 1.1rem;
        text-align: center;

        & + * {
          margin-top: 0.5rem;
        }
      }

      & p + p {
        margin-top: 0rem;
      }
    }
  }
}

.image-carousel-scroll-indicator {
  display: flex;
  margin: 0 auto;
  padding: 0 var(--section-horizontal-padding);
  max-width: var(--section-max-width-narrow);

  @media (max-width: 800px) {
    width: 100%;
  }

  & span {
    margin: 0 3px;
    position: relative;
    width: 100%;

    &::before {
      content: '';
      position: absolute;
      top: calc(50% - 2px);
      left: 0;
      right: 0;
      height: 2px;
      background: #cfcfcf;
      transition: all 0.3s ease-in-out;
    }

    &.cur::before {
      height: 3px;
      background: #a1a1a1;
    }
  }
}

.image-carousel-scroll-buttons {
  /* margin-bottom: var(--section-heading-and-body-margin); */
  display: grid;
  grid-auto-flow: column;
  grid-gap: 1rem;
  margin-left: auto;
  /* height: 54px; */

  & button,
  & button:focus {
    background: none;
    border: 0;
    outline: 0;
  }

  & button:hover {
    background: none;
    border: 0;
    outline: 1px solid #aaa;
  }

  & button {
    background: none;
    /* border-radius: 50%; */
    /* z-index: 2; */
    /* padding: 0.5rem; */
    width: 40px;
    height: 40px;
    /* transition: all 0.2s ease-in-out; */
    user-select: none;

    @media (max-width: 600px) {
      width: 40px;
      height: 40px;
    }

    & svg {
      width: 100%;
      height: 100%;
      color: #666;
    }
  }

  .prev {
  }
  .next {
  }
}
