#intro {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: var(--intro-height);
  z-index: var(--intro-z-index);
  background-color: var(--intro-bg-color);
  /* background-image: url('/workspace.jpg'); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top right;
  display: flex;
  color: white;
  container-type: size;

  &.hidden {
    visibility: hidden;
  }

  > .content-wrapper {
    /* margin: 0 auto; */
    /* padding: var(--section-vertical-padding) var(--section-horizontal-padding); */
    /* max-width: var(--section-max-width-narrow); */

    flex: 1;
    display: flex;
    /* backdrop-filter: blur(5px); */
    /* -webkit-backdrop-filter: blur(5px); */

    > .content {
      flex: 1;
      padding: 0 var(--section-horizontal-padding);
      max-width: var(--section-max-width-narrow);
      margin: calc(var(--navbar-height) / 2) auto 0 auto;
      /* margin: 0 auto 0 16%; */
      display: grid;
      align-items: center;
      align-content: center;
      /* flex-direction: column; */
      /* padding-right: 10rem; */
      position: relative;
      /* min-height: 600px; */

      @container (max-width: 1024px) {
        /* padding: 0 3rem; */
        /* margin: auto; */
        /* margin: 0 auto; */
        text-align: center;
        /* padding: 0 5rem; */
      }

      /* margin: auto 0; */
      /* text-shadow: 2px 5px 15px #000000; */

      & h2 {
        margin-bottom: 0.5rem;
        font-size: 1.5rem;
        /* letter-spacing: 4px; */
        font-weight: normal;
        color: #ddd;

        @container (max-width: 700px) {
          font-size: 1.2rem;
          font-weight: bold;
        }

        @container (max-height: 600px) {
          font-size: 1rem;
        }

        @container (max-height: 600px) {
          font-size: 1rem;
        }
      }

      & h1 {
        margin-bottom: 1rem;
        /* margin-top: 1rem; */
        /* margin-left: -0.5rem; */
        font-size: 4rem;
        line-height: 1.1;

        @container (max-width: 700px) {
          /* margin-left: 0; */
          font-size: 2.5rem;
        }
        @container (max-height: 600px) {
          font-size: 2rem;
        }
        @container (max-height: 400px) {
          font-size: 1.5rem;
        }

        .primary {
          white-space: nowrap;
          color: var(--primary-color);
        }
      }

      .button-container {
        /* position: absolute; */
        /* left: 0; */
        /* right: 0; */
        /* bottom: 2rem; */
        display: flex;
        justify-content: flex-start;

        @container (max-width: 1024px) {
          justify-content: center;
        }

        @container (max-width: 700px) {
          /* margin-top: 10rem; */
        }
        @container (max-width: 400px) {
          /* margin-top: 8rem; */
        }
        @container (max-height: 500px) {
          /* display: none; */
          & a.book-button {
            /* line-height: 0; */
            padding: 0.75rem 1rem;
          }
        }
      }
    }
  }
}
